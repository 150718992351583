@import '../../node_modules/bootstrap/scss/bootstrap.scss';

.copy {
	text-align: center;
	color: #666;
	padding-top: 10px;
	margin-top: 0px;
	font-size: 85%;
}

:root { 
	--qlwapp-scheme-brand: #20B038; 
	--qlwapp-scheme-text: #ffffff;
}

#qlwapp .qlwapp-toggle, #qlwapp .qlwapp-box .qlwapp-header, #qlwapp .qlwapp-box .qlwapp-user,
	#qlwapp .qlwapp-box .qlwapp-user:before {
	background-color: var(- -qlwapp-scheme-brand);
}

#qlwapp .qlwapp-toggle, #qlwapp .qlwapp-toggle .qlwapp-icon, #qlwapp .qlwapp-toggle .qlwapp-text,
	#qlwapp .qlwapp-box .qlwapp-header, #qlwapp .qlwapp-box .qlwapp-user {
	color: var(- -qlwapp-scheme-text);
}

img.wp-smiley, img.emoji {
	display: inline !important;
	border: none !important;
	box-shadow: none !important;
	height: 1em !important;
	width: 1em !important;
	margin: 0 .07em !important;
	vertical-align: -0.1em !important;
	background: none !important;
	padding: 0 !important;
}

@media only screen and (min-width: 992px) {
	.header-image {
		height: 600px;
	}
}

.roll-testimonials:before, .roll-testimonials .name, .roll-news .entry .title a:hover,
	.roll-news .entry .meta span a:hover, .roll-progress .perc,
	.roll-iconbox.style2 .icon, .roll-iconbox.border .icon, .widget.widget-info li:before,
	.widget-area .widget li a:hover, .post .post-meta a:hover, #mainnav ul li a.active,
	#mainnav ul li a:hover, a, .social-area a, .post .entry-footer .fa,
	.post .post-meta .fa, .preloader .preloader-inner {
	color: #ffa800;
}

.header-text::before, .roll-team .overlay .socials li a, .rocked-toggle .toggle-title.active,
	.rocked-toggle .toggle-title.active, .roll-progress .animate,
	.roll-iconbox.border:hover .icon, .roll-iconbox .icon, .roll-button,
	.owl-theme .owl-controls .owl-page.active span, .work-faetures .box .icon,
	.widget.widget-tags .tags a:hover, .page-pagination ul li.active,
	.page-pagination ul li:hover a, .post .post-format, #mainnav ul ul li:hover>a,
	#mainnav ul li ul:after, button, input[type="button"], input[type="reset"],
	input[type="submit"], .social-area a:hover {
	background-color: #ffa800;
}

.roll-iconbox.border .icon, .owl-theme .owl-controls .owl-page.active span,
	.widget.widget-tags .tags a:hover, .social-area a {
	border-color: #ffa800;
}

#mainnav ul li ul:before {
	border-color: transparent transparent #ffa800 transparent;
}

.header {
	background-color: #ffffff;
}

.site-title a, .site-title a:hover {
	color: #222
}

.site-description {
	color: #222
}

#mainnav ul li a {
	color: #222
}

#mainnav ul ul li a {
	color: #222
}

.header-text, .header-title {
	color: #ffffff
}

body {
	color: #777
}

.footer-widgets.footer {
	background-color: #2d2d2d
}

.row-overlay {
	background-color: #1c1c1c
}

.header-image::after {
	background-color: #000
}

.site-title {
	font-size: 38px;
}

.site-description {
	font-size: 14px;
}

.recentcomments a {
	display: inline !important;
	padding: 0 !important;
	margin: 0 !important;
}

.header-image {
	background-image: url(../images/cropped-foto-metro.jpg);
	display: block;
}

/* Layout 13 */
#pgc-13-0-0, #pgc-13-1-0, #pgc-13-2-0, #pgc-13-4-0, #pgc-13-6-0 {
	width: 100%;
	width: calc(100% - ( 0 * 30px))
}

#pl-13 #panel-13-0-0-0, #pl-13 #panel-13-1-0-0, #pl-13 #panel-13-2-0-0,
	#pl-13 #panel-13-3-0-0, #pl-13 #panel-13-3-1-0, #pl-13 #panel-13-3-2-0,
	#pl-13 #panel-13-3-3-0, #pl-13 #panel-13-3-4-0, #pl-13 #panel-13-4-0-0,
	#pl-13 #panel-13-5-0-0, #pl-13 #panel-13-5-1-0, #pl-13 #panel-13-5-1-1,
	#pl-13 #panel-13-6-0-0 {
	
}

#pg-13-0, #pg-13-1, #pg-13-2, #pg-13-3, #pg-13-4, #pg-13-5, #pl-13 .so-panel,
	#pl-13 .so-panel:last-child {
	margin-bottom: 0px
}

#pgc-13-3-0, #pgc-13-3-1, #pgc-13-3-2, #pgc-13-3-3, #pgc-13-3-4 {
	width: 20%;
	width: calc(20% - ( 0.8 * 30px))
}

#pgc-13-5-0, #pgc-13-5-1 {
	width: 50%;
	width: calc(50% - ( 0.5 * 30px))
}

#pg-13-0>.panel-row-style {
	padding: 50px
}

#pg-13-0.panel-no-style, #pg-13-0.panel-has-style>.panel-row-style {
	-webkit-align-items: flex-start;
	align-items: flex-start
}

#pg-13-1>.panel-row-style {
	background-color: #e8e8e8;
	padding: 30px
}

#panel-13-1-0-0>.panel-widget-style {
	padding: 0px 50px 0px 50px
}

#pg-13-2>.panel-row-style, #pg-13-3>.panel-row-style, #pg-13-4>.panel-row-style,
	#pg-13-5>.panel-row-style, #pg-13-6>.panel-row-style {
	padding: 40px
}

#panel-13-5-1-1>.panel-widget-style {
	padding: 0px 150px 0px 0px
}

@media ( max-width :780px) {
	#pg-13-0.panel-no-style, #pg-13-0.panel-has-style>.panel-row-style,
		#pg-13-1.panel-no-style, #pg-13-1.panel-has-style>.panel-row-style,
		#pg-13-2.panel-no-style, #pg-13-2.panel-has-style>.panel-row-style,
		#pg-13-3.panel-no-style, #pg-13-3.panel-has-style>.panel-row-style,
		#pg-13-4.panel-no-style, #pg-13-4.panel-has-style>.panel-row-style,
		#pg-13-5.panel-no-style, #pg-13-5.panel-has-style>.panel-row-style,
		#pg-13-6.panel-no-style, #pg-13-6.panel-has-style>.panel-row-style {
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	#pg-13-0>.panel-grid-cell, #pg-13-0>.panel-row-style>.panel-grid-cell,
		#pg-13-1>.panel-grid-cell, #pg-13-1>.panel-row-style>.panel-grid-cell,
		#pg-13-2>.panel-grid-cell, #pg-13-2>.panel-row-style>.panel-grid-cell,
		#pg-13-3>.panel-grid-cell, #pg-13-3>.panel-row-style>.panel-grid-cell,
		#pg-13-4>.panel-grid-cell, #pg-13-4>.panel-row-style>.panel-grid-cell,
		#pg-13-5>.panel-grid-cell, #pg-13-5>.panel-row-style>.panel-grid-cell,
		#pg-13-6>.panel-grid-cell, #pg-13-6>.panel-row-style>.panel-grid-cell
		{
		width: 100%;
		margin-right: 0
	}
	#pgc-13-3-0, #pgc-13-3-1, #pgc-13-3-2, #pgc-13-3-3, #pgc-13-5-0, #pl-13 .panel-grid .panel-grid-cell-mobile-last
		{
		margin-bottom: 0px
	}
	#pl-13 .panel-grid-cell {
		padding: 0
	}
	#pl-13 .panel-grid .panel-grid-cell-empty {
		display: none
	}
}

.fluid-width-video-wrapper {
	width: 100%;
	position: relative;
	padding: 0;
}

.fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object,
	.fluid-width-video-wrapper embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

//Para o light box

* {
  box-sizing: border-box;
}

.row > .column {
  padding: 0 1px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 25%;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 99;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 600px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

img {
  margin-bottom: -4px;
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hidden {
  display: none;
  visibility: hidden;
}